import React, { useState, useEffect } from 'react';
import Header from './Header';
import ChatArea from './ChatArea';
import InputArea from './InputArea';

function ChatbotApp() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [questions, setQuestions] = useState({});
  const [frequentQuestions, setFrequentQuestions] = useState({});

  useEffect(() => {
    fetch('https://njsbcl.fleetstudio.com/api/latest-questions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        let filteredQuestions = {}
        data.forEach(item => {
          filteredQuestions[item.message] = item.answer;
        });
        setQuestions(filteredQuestions);
      })
      .catch(error => {
        console.error('Error:', error);
      });

    fetch('https://njsbcl.fleetstudio.com/api/frequent-questions', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        let frequentQuestions = {}
        data.forEach(item => {
          frequentQuestions[item.message] = item.answer;
        });
        setFrequentQuestions(frequentQuestions);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [])

  const sendMessage = (text) => {
    if (text && text.trim()) {
      setIsLoading(true);
      setMessages(messages => [...messages, { text, from: 'user' }]);

      fetch('https://njsbcl.fleetstudio.com/api/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: text })
      })
      .then(response => response.json())
      .then(data => {
        setMessages(messages => [...messages, { text: data.answer, from: 'system' }]);
        setIsLoading(false); // Hide loader after receiving the response
      })
      .catch(error => {
        console.error('Error:', error);
        setMessages(messages => [...messages, { text: 'Error in fetching response', from: 'system' }]);
        setIsLoading(false); // Hide loader if an error occurs
      });
    }
  };

  const sendAnswer = (question, answer) => {
    setIsLoading(true);
    setMessages(messages => [...messages, { text: question, from: 'user' }, { text: answer, from: 'system' }]);
    setIsLoading(false);
  }

  const toggleSlider = () => {
    setShowSlider(!showSlider);
  }

  return (
    <div className="bg-gray-900 h-full flex flex-col mx-auto lg:mx-20 xl:mx-40 shadow-lg">
      <Header toggleSlider={toggleSlider} showSlider={showSlider} />
      <ChatArea messages={messages} isLoading={isLoading} showSlider={showSlider} onSendAnswer={sendAnswer} toggleSlider={toggleSlider} questions={questions} frequentQuestions={frequentQuestions} />
      <InputArea onSendMessage={sendMessage} isLoading={isLoading} />
    </div>
  );
}

export default ChatbotApp;
