import React from 'react';

function Header({ toggleSlider, showSlider }) {

  return (
    <div className="bg-customDarkBg bg-opacity-50 text-white p-2 sm:p-4 flex justify-between items-center"
         style={{
           boxShadow: '0 10px 15px -3px rgba(255, 255, 255, 0.2), 0 4px 6px -2px rgba(255, 255, 255, 0.1)'
         }}>
      <div className="font-semibold flex items-center gap-4">
        <img src="./njsbcl.jpeg" alt="NJSBCL LOGO" className='w-8 h-8' />
        NJSBCL - AI Assistant
      </div>
      <div className="flex justify-end">
        <button onClick={toggleSlider} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 sm:px-4 rounded text-sm sm:text-base">
          FAQ
          <i className={`fas fa-times text-white rounded-full pl-2 sm:pl-4 transition-colors ${showSlider ? 'visible' : 'invisible'}`} aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
}

export default Header;
