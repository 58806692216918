import React from 'react';
import './App.css';
import ChatbotApp from './ChatbotApp';
import useViewportHeight from './useViewportHeight';

function App() {
  const viewportHeight = useViewportHeight();

  // Apply the viewport height to a CSS variable on the root element
  document.documentElement.style.setProperty('--app-height', `${viewportHeight}px`);

  return (
    <div style={{ height: 'var(--app-height)' }}>
      <ChatbotApp />
    </div>
  );
}

export default App;
