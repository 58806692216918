import React from 'react';
import PromptScrollBar from './PromptScrollBar';

const Prompts = ({ handlePromptSelect, isLoading }) => {
  const items = {
    "Team Registration": "What are the registration requirements for teams in the NJSBCL?",
    "Match Forfeits": "What are the consequences for a team that forfeits a match?",
    "Super Over Rules": "How is a tie resolved in knockout matches?",
    "Player Eligibility": "What are the eligibility requirements for players in the knockout rounds?",
    "Match Rescheduling": "Under what circumstances can matches be rescheduled?",
    "Promotions and Demotions": "How are teams promoted or demoted between divisions?",
    "Umpiring": "What are the responsibilities of neutral umpires?",
    "Scoring System": "How is the NJSBCL live scoring app used during matches?",
    "Weather Policy": "What are the rules for matches affected by weather?",
    "Dispute Resolution": "How are disputes resolved during the tournament?"
  };


  const handleSelect = (key) => {
    handlePromptSelect(items[key]);
  };

  return (
    <div>
      <PromptScrollBar items={items} onSelect={handleSelect} loading={isLoading} />
    </div>
  );
};

export default Prompts;
