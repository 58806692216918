import React, { useState, useRef } from 'react';
import Prompts from './Prompts';

function InputArea({ onSendMessage, isLoading }) {
  const [inputText, setInputText] = useState('');
  const inputTextRef = useRef(null); // Ref for text input to manage focus

  const handleSendClick = () => {
    onSendMessage(inputText);
    setInputText('');
    inputTextRef.current.focus(); // Focus back to input after send
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSendClick();
      event.preventDefault(); // Prevent default to stop form submission if any
    }
  };

  return (
    <div>
      <Prompts handlePromptSelect={onSendMessage} isLoading={isLoading} />
      <div className="bg-customGreenBg p-2 flex flex-col items-center gap-4">
        <div className='flex flex-row w-full gap-2 justify-between'>
          <a
            href="https://njsbcl.fleetstudio.com/api/download"
            download
            className="bg-white text-customDarkBg rounded-full py-2 px-3 hover:bg-gray-200 transition-colors"
            aria-label="Download report"
          >
            <i className="fas fa-download" aria-hidden="true"></i>
          </a>
          <input
            ref={inputTextRef}
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            onKeyDown={handleKeyPress} // Handle enter key press for sending message
            placeholder="Type your message here..."
            aria-label="Type your message"
            className="flex-1 p-2 border border-customGreenBorder rounded-md focus:outline-none focus:border-white transition-colors"
            disabled={isLoading}
          />
          <button
            onClick={handleSendClick}
            className="bg-white text-customGreenBg rounded-full py-2 px-3 ml-2 hover:bg-gray-200 transition-colors"
            aria-label="Send message"
          >
            <i className="fas fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
        <div className='flex flex-col md:flex-row text-white justify-between items-center w-full px-2'>
          <div className='flex items-center'>
            <a href="http://www.njsbcl.com/NJSBCL/viewTeam.do?teamId=2834&clubId=2690" target='_blank' rel="noreferrer">
              Built with &#9829; by your friends at MKCC &#9829;
            </a>
            <a href="http://www.njsbcl.com/NJSBCL/viewTeam.do?teamId=2834&clubId=2690" target='_blank' rel="noreferrer">
              <img src="./mkcc.jpg" alt="MKCC Logo" className='w-4 h-4 ml-2' />
            </a>
            </div>
          <div className='flex items-center'><a href="https://fleetstudio.com/" target="_blank" rel="noreferrer">Powered by &nbsp;</a><a href="https://fleetstudio.com/" target="_blank" rel="noreferrer"><img src="./fleet-logo.png" alt="fleet studio" className='w-24 h-5 mb-1' /></a></div>
        </div>
      </div>
    </div>
  );
}

export default InputArea;
