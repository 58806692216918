import React from 'react';

const PromptScrollBar = ({ items, onSelect, loading }) => {
  return (
    <div className="flex overflow-x-auto space-x-4 p-4">
      {Object.keys(items).map((key, index) => (
        <div
          key={index}
          className={`inline-block bg-blue-800 text-white py-2 px-4 rounded-lg shadow-lg min-w-max ${loading ? 'pointer-events-none cursor-not-allowed' : 'pointer-events-auto cursor-pointer'}`}
          onClick={!loading ? () => onSelect(key) : undefined}
          role={!loading ? "button" : undefined}
          tabIndex={!loading ? 0 : undefined}
        >
          {key}
        </div>
      ))}
    </div>
  );
};

export default PromptScrollBar;
