import React, { useEffect, useRef, useState } from 'react';

const ChatArea = ({ messages, isLoading, showSlider, onSendAnswer, toggleSlider, questions, frequentQuestions }) => {
  const messagesEndRef = useRef(null);
  const chatAreaRef = useRef(null);
  const [sliderStyle, setSliderStyle] = useState({});
  const [topQuestionsOpen, setTopQuestionsOpen] = useState(false);
  const [frequentQuestionsOpen, setFrequentQuestionsOpen] = useState(false);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    if (chatAreaRef.current) {
      const rect = chatAreaRef.current.getBoundingClientRect();
      setSliderStyle({
        top: `${rect.top}px`,
        bottom: `${window.innerHeight - rect.bottom}px`,
        right: `${window.innerWidth - rect.right}px`,
        maxHeight: `${rect.height}px`,
        overflowY: 'auto'
      });
    }
  }, [messages, chatAreaRef.current, window.innerHeight]);

  const handleQuestionClick = (question, answer) => {
    onSendAnswer(question, answer);
    toggleSlider();
  };

  return (
    <div ref={chatAreaRef} className="relative flex-1 p-4 overflow-y-auto bg-customDarkBg text-white">
      {messages.map((msg, index) => (
        <div key={index} className={`flex items-end ${msg.from === 'user' ? 'justify-end' : 'justify-start'}`}>
          <div className={`${msg.from === 'user' ? 'bg-customGreenBg' : 'bg-teal'} rounded-lg px-4 py-2 max-w-xs m-2`}>
            {msg.text}
          </div>
        </div>
      ))}
      {isLoading && <div className="flex justify-start items-center py-2">
        <div className="bg-teal rounded-lg px-4 py-2 max-w-xs m-2 flex items-center gap-1">
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing"></div>
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing" style={{ animationDelay: '0.2s' }}></div>
          <div className="w-2 h-2 bg-white rounded-full animate-dotFlashing" style={{ animationDelay: '0.4s' }}></div>
        </div>
      </div>}
      <div ref={messagesEndRef} />
      {showSlider && (
        <div className={`fixed bg-midnightBlue text-white p-4 shadow-lg z-50 w-full sm:w-3/5 md:w-2/5`} style={sliderStyle}>
          <ul className="space-y-4">
            {questions && <li className='mb-4'>
              <div className="cursor-pointer flex justify-between" onClick={() => setTopQuestionsOpen(!topQuestionsOpen)}>
                <h3 className="hover:text-blue-300 transition-colors mb-4 font-bold">Latest Questions</h3>
                {topQuestionsOpen ? <i className="fas fa-chevron-up" aria-hidden="true"></i> : <i className="fas fa-chevron-down" aria-hidden="true"></i>}
              </div>
              <ul className={topQuestionsOpen ? 'block' : 'hidden'}>
                {questions && Object.entries(questions).map(([key, value]) => (
                  <li key={key} className="cursor-pointer hover:text-blue-300 transition-colors" onClick={() => handleQuestionClick(key, value)}>
                    {key}
                    <hr className="border-t-[0.5px] border-gray-700 mt-2" />
                  </li>
                ))}
              </ul>
            </li>}
            {frequentQuestions && <li>
              <div className="cursor-pointer flex justify-between" onClick={() => setFrequentQuestionsOpen(!frequentQuestionsOpen)}>
                <h3 className="hover:text-blue-300 transition-colors mb-4 font-bold">Frequently Asked Questions</h3>
                {frequentQuestionsOpen ? <i className="fas fa-chevron-up" aria-hidden="true"></i> : <i className="fas fa-chevron-down" aria-hidden="true"></i>}
              </div>
              <ul className={frequentQuestionsOpen ? 'block' : 'hidden'}>
                {frequentQuestions && Object.entries(frequentQuestions).map(([key, value]) => (
                  <li key={key} className="cursor-pointer hover:text-blue-300 transition-colors" onClick={() => handleQuestionClick(key, value)}>
                    {key}
                    <hr className="border-t-[0.5px] border-gray-700 mt-2" />
                  </li>
                ))}
              </ul>
            </li>}
          </ul>
        </div>
      )}
    </div>
  );
}

export default ChatArea;
